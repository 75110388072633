import type { InjectionKey } from 'vue'

// models
import {
  ProfileData,
  getProfileResponse,
  GetProfileResponse,
  PostProfileRequest,
  postProfileResponse,
  PostProfileResponse,
  PatchProfileResponse,
  patchProfileResponse,
} from '@/models/profiles'

// modules
import { isValueOf } from '@/utils/zod'
import { getFileByURL } from '@/utils/file'

// repositories
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import { isFetchError } from '@/composables/repository/useOhmyfetch'

// composables
import { useAuth } from '@/composables/useAuth'

// note: testのためexport
export type ProfileStateType = {
  isFetching: boolean
  profile: null | ProfileData // MyVketのprofile
}

export const useMyProfile = () => {
  const repositoryFactory = useRepositoryFactory()
  const profileRepository = repositoryFactory.get('profile')

  const { getDefaultLanguage } = useLocale()

  const { me } = useAuth()

  /**
   * ユーザプロフィールのstate
   */
  const state = useState<ProfileStateType>('profile_state', () => ({
    isFetching: false,
    profile: null,
  }))

  // 自分のプロフィールを取得できているか？
  const isMyProfileAcquired = computed(() => {
    return !!state.value.profile?.vketId
  })

  /**
   * MyVketのprofileを初期化
   */
  const initProfile = () => {
    state.value.profile = null
  }

  /**
   * 自身のMyVketのプロフィール取得
   */
  const getMyProfile = async () => {
    try {
      state.value.isFetching = true
      const response = await profileRepository.get.getMyProfile()

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf<GetProfileResponse>(getProfileResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.profile = response.profile
      // TODO: 型指定
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        // 404 -> Vketアカウントの状態を自動登録
        await postMyFirstProfile()
      }
    } finally {
      state.value.isFetching = false
    }
    return state.value.profile
  }

  /**
   * 初回の自動プロフィール登録
   * note: getMyProfileで404の時に実行
   */
  const postMyFirstProfile = async () => {
    try {
      if (!me.value) {
        throw new Error('Failed to get profile.')
      }

      // vket account のicon画像があればFileデータ化
      const file = me.value.pictureUrl
        ? await getFileByURL(me.value.pictureUrl)
        : null

      state.value.profile = await postProfile({
        name:
          (getDefaultLanguage() === 'ja' ? me.value.nameJa : me.value.nameEn) ||
          '',
        introduction: '',
        icon: file,
      })
    } catch (error: unknown) {
      // profileの内容でエラーになった場合、デフォルトで登録する
      if (isFetchError(error) && error.response?.status === 422) {
        state.value.profile = await postProfile({
          name: 'New User',
          introduction: '',
          icon: null,
        })
      }

      console.error(error)
    }
  }

  /**
   * ユーザーのMyVklet profile登録
   * @param name ユーザー名
   * @param introduction 紹介文
   * @param icon アイコン
   */
  const postProfile = async (
    postData: PostProfileRequest
  ): Promise<ProfileData | null> => {
    const response = await profileRepository.post.postProfile(postData)

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf<PostProfileResponse>(postProfileResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.profile = response.profile

    return response.profile
  }

  /**
   * ユーザーのMyVklet profile更新
   * @param name 新規ユーザー名
   * @param introduction 新規紹介文
   * @param icon 新規アイコン
   */
  const patchProfile = async ({
    name,
    introduction,
    topPageAvatarId,
    deleteTopPageAvatarId,
    icon,
    showAchievements,
  }: {
    name: string
    introduction: string | null
    topPageAvatarId?: number | null
    deleteTopPageAvatarId?: boolean
    icon?: File | null
    showAchievements?: boolean
  }) => {
    if (!state.value.profile) return null
    const response = await profileRepository.patch.patchProfile({
      name,
      introduction,
      topPageAvatarId,
      deleteTopPageAvatarId,
      icon,
      showAchievements,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf<PatchProfileResponse>(patchProfileResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.profile = response.profile

    return response.profile
  }

  /**
   * プロフィールトップに表示するアバターを更新する
   * @param assetId アセットID, nullの場合は削除
   * @returns 更新結果
   */
  const patchProfileTopPageAvatar = async (assetId: number | null) => {
    const profile = state.value.profile
    if (!profile) throw new Error('empty profile')
    if (assetId === null) {
      return await patchProfile({
        name: profile.name,
        introduction: profile.introduction,
        deleteTopPageAvatarId: true,
      })
    } else {
      return await patchProfile({
        name: profile.name,
        introduction: profile.introduction,
        topPageAvatarId: assetId,
      })
    }
  }

  return {
    state: readonly(state),
    isMyProfileAcquired,
    initProfile,
    getMyProfile,
    postProfile,
    patchProfile,
    patchProfileTopPageAvatar,
  }
}

export type MyProfileComposable = ReturnType<typeof useMyProfile>

export const myProfileInjectionKey: InjectionKey<MyProfileComposable> =
  Symbol('my-profile')
